import { navigate } from "gatsby"
import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Spinner from "react-bootstrap/Spinner"
import ReCAPTCHA from "react-google-recaptcha"
import Select, { components } from "react-select"
import vector4 from "../../images/new-ppc/vector6.svg"
import vector2 from "../../images/new-ppc/vector7.svg"
import vector1 from "../../images/new-ppc/vector8.svg"
import vector3 from "../../images/new-ppc/vector9.svg"
import vector5 from "../../images/new-ppc/vectorbox.png"
import image from "../../images/PPC/select.svg"
import PhoneCustomInput from "../common/phoneInput"
import { encodeToFormData, isEmail, SITE_KEY } from "../../utils"
import * as styles from "./PpcForm.module.scss"
import "./ppc.scss"

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <img decoding="async" loading="lazy" src={image} alt="background" />
    </components.DropdownIndicator>
  )
}

const PpcForm = props => {
  const [status, setStatus] = useState("init")
  const [captcha, isCaptcha] = useState(false)
  const [mapBoxneeded, setBoxmapBoxneeded] = useState(false)
  const [phoneFormat, setPhoneFormat] = useState(null)
  const [isValidPhoneFormat, setIsValidPhoneFormat] = useState(true)
  const [recaptchaNeeded, setRecaptchaNeeded] = useState(false)

  const initValues = {
    fullName: "",
    email: "",
    phoneContact: "",
    projects: {},
    otherProject: "",
    message: "",
    terms: false,
    captcha: false,
  }

  const REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g

  const AboutProjects = [
    {
      label: "Web App Development",
      value: false,
    },
    {
      label: "Mobile App Development",
      value: false,
    },
    {
      label: "Software Development & Consultation",
      value: false,
    },
    {
      label: "Blockchain Development",
      value: false,
    },
    // {
    //   label: "Cloud Services",
    //   value: false,
    // },
    {
      label: "Game Development",
      value: false,
    },
    {
      label: "Other (Please specify)",
      value: false,
    },
  ]

  const [values, setValues] = useState(initValues)
  const [errors, setErrors] = useState({})

  const handleChange = e => {
    const { name, value, type } = e.target
    if (type === "checkbox") {
      e.target.checked
        ? (values.projects[name] = e.target.checked)
        : delete values.projects[name]
    } else {
      values[name] = value
    }

    setValues({ ...values })
    // setRecaptchaNeeded(true)

    if (errors.hasOwnProperty(name)) {
      delete errors[name]
      setErrors(errors)
    }
  }
  const checbox = e => {
    const { name, type } = e.target
    if (type === "checkbox") {
      values[name] = e.target.checked
    }
    setValues({ ...values })
    setRecaptchaNeeded(true)

    if (errors.hasOwnProperty(name)) {
      delete errors[name]
      setErrors(errors)
    }
  }

  const onChangeReCaptcha = value => {
    // isCaptcha({ ...captcha, code: value, validation: true })
    values["captcha"] = value
    setValues({ ...values })

    if (errors.hasOwnProperty("captcha")) {
      delete errors["captcha"]
      setErrors(errors)
    }
  }

  const validate = data => {
    const newErrors = {}
    for (const key in data) {
      const value = data[key]
      switch (key) {
        case "fullName":
          if (!value || value?.toString()?.trim() === "")
            newErrors[key] = "This field is required!"
          else if (value?.toString()?.trim().length > 50)
            newErrors[key] = "The length should not exceed 50 characters!"
          break
        case "message":
          if (value.toString().trim() === "")
            newErrors[key] = "This field is required!"
          break
        case "email":
          if (!value || value?.toString()?.trim() === "")
            newErrors[key] = "This field is required!"
          else if (!isEmail(value)) newErrors[key] = "Email must be valid!"
          break
        case "otherProject":
          if (
            (Object.keys(values?.projects)?.includes(
              "Other (Please specify)"
            ) &&
              !value) ||
            value.value === ""
          )
            newErrors[key] = "This field is required!"
          break
        case "terms":
          if (value === false)
            newErrors[key] = "You have to Accept the terms & conditions"
          break
        case "captcha":
          if (!value) newErrors[key] = "This field is required!"
          break
        // case "phoneContact":
        //   if (!value || value.value === "")
        //     newErrors[key] = "This field is required!"
        //   break
        default:
          break
      }
    }
    return newErrors
  }

  const options = [
    { value: "Staff Augmentation", label: "Staff Augmentation" },
    { value: "Dedicated Team", label: "Dedicated Team" },
    { value: "Fixed Gigs", label: "Fixed Gigs" },
    // { value: "Cybersecurity", label: "Cybersecurity" },
    { value: "Metaverse", label: "Metaverse" },
    { value: "MVP Development", label: "MVP Development" },
    {
      value: "Software Product Development",
      label: "Software Product Development",
    },
    { value: "General Inquiry", label: "General Inquiry" },
  ]

  const handleSubmit = async e => {
    e.preventDefault()
    setRecaptchaNeeded(true)
    const validationErrors = validate(values)
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }
    setStatus("submitting")

    let url = `${process.env.GATSBY_STRAPI_API_URL}/api/contactusforms/?id=9010`

    const formData = encodeToFormData({
      ...values,
      email: values.email,
      fullName: values.fullName,
      contact: values.phoneContact,
      projects: JSON.stringify(values.projects),
      captchaToken: values?.captcha,
    })

    try {
      const result = await fetch(url, {
        method: "POST",
        body: formData,
      }).then(res => {
        if (res.status === 429) {
          setStatus("failed")
          return
        }
        return res.json()
      })
      if (
        !result.hasOwnProperty("error") ||
        result.status === "mail_sent" ||
        result.status === "mail_failed"
      ) {
        navigate("/thank-you/", {
          state: {
            pathname: window.location.pathname,
            fullName: values?.fullName,
            email: values?.email,
          },
        })
        // localStorage.setItem("invo_user_email", local_data.email)
        // localStorage.setItem("invo_user_name", local_data.fullName)
        // setValues(initValues)
        // setStatus("success")
      } else {
        setStatus("failed")
        throw new Error("Server error!")
      }
      // if (result.message === "Email send successfully") {
      //   navigate("/thank-you/", {
      //     state: { pathname: window.location.pathname },
      //   })
      //   setValues(initValues)
      //   setStatus("success")
      // } else {
      //   throw new Error("Server error!")
      // }
    } catch (err) {
      setStatus("failed")
    }
  }

  return (
    <>
      {!props.bottom && (
        <>
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <div className={styles.vector1}>
              <img
                decoding="async"
                loading="lazy"
                src={vector1}
                alt="vector1"
              />
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <div className={styles.vector2}>
              <img
                decoding="async"
                loading="lazy"
                src={vector2}
                alt="vector2"
              />
            </div>
          </div>
          <div
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <div className={styles.vector3}>
              <img
                decoding="async"
                loading="lazy"
                src={vector4}
                alt="vector3"
              />
            </div>
          </div>
          <div
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <div className={styles.vector4}>
              <img
                decoding="async"
                loading="lazy"
                src={vector3}
                alt="vector4"
              />
            </div>
          </div>
          <div className={styles.vector5}>
            <img decoding="async" loading="lazy" src={vector5} alt="vector5" />
          </div>
        </>
      )}

      <div
        className={`${styles.ppcForm} ${props?.bottom ? styles.b : styles.s}`}
      >
        <div className={styles.form}>
          <p className={styles.formTitle}>Let's Talk Business</p>
          <Form name="PPC Form" method="POST" onSubmit={handleSubmit}>
            <Row className="mt-3 mb-2">
              <Col lg={6}>
                <Form.Group controlId="name" className={styles.formInput}>
                  <Form.Control
                    type="text"
                    placeholder="Full Name*"
                    name="fullName"
                    onKeyPress={e => {
                      if (values.fullName.length >= 50) {
                        return e.preventDefault()
                      }
                    }}
                    value={values.fullName}
                    onChange={handleChange}
                    isInvalid={errors.fullName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.fullName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="Email" className={styles.formInput}>
                  <Form.Control
                    type="text"
                    placeholder="Email Address*"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group
                  controlId="phoneNumber"
                  // className={` ${styles.formInput}`}
                >
                  {/* <Form.Label className="font-weight-normal px-1">
                  Phone Number
                </Form.Label> */}

                  <div className="ppcNumber">
                    <PhoneCustomInput
                      className={styles.formInput}
                      value={values.phoneContact}
                      mapBoxneeded={mapBoxneeded}
                      onFocus={() => setBoxmapBoxneeded(true)}
                      onChange={(value, data, e, formattedValue) => {
                        setPhoneFormat(formattedValue)
                        const { dialCode, countryCode } = data
                        setValues({
                          ...values,
                          phoneContact: value,
                          phoneNumber: value?.slice(dialCode?.length),
                          countryCode: dialCode,
                          countryName: countryCode,
                        })
                      }}
                      isValid={(value, country) => {
                        const { format, dialCode } = country
                        if (
                          format?.length === phoneFormat?.length &&
                          (value?.startsWith(dialCode) ||
                            dialCode?.startsWith(value))
                        ) {
                          setIsValidPhoneFormat(true)
                          return true
                        } else if (value) {
                          setIsValidPhoneFormat(false)
                          return "Invalid Format"
                        } else if (value.match(/12345/)) {
                          setIsValidPhoneFormat(false)
                          return "Invalid Format"
                        } else if (value.match(/1234/)) {
                          setIsValidPhoneFormat(false)
                          return false
                        } else {
                          setIsValidPhoneFormat(true)
                          return true
                        }
                      }}
                    />
                  </div>
                  {errors.phoneContact && (
                    <small
                      type="invalid"
                      className="text-danger position-absolute"
                    >
                      {errors.phoneContact}
                    </small>
                  )}
                </Form.Group>
              </Col>
              {/* <Form.Group controlId="Contact" className={styles.formInput}>
                  <Form.Control
                    type="number"
                    placeholder="Contact number*"
                    name="contact"
                    min="0"
                    onKeyPress={e => {
                      if (
                        e.code === "Minus" ||
                        e.code === "NumpadSubtract" ||
                        e.code === "Comma" ||
                        e.code === "NumpadAdd" ||
                        e.code === "Period" ||
                        e.key === "e" ||
                        e.key === "E" ||
                        !REGEX.test(e.key)
                      ) {
                        e.preventDefault()
                      }
                    }}
                    value={values.contact}
                    onChange={handleChange}
                    isInvalid={errors.contact}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.contact}
                  </Form.Control.Feedback>
                </Form.Group> */}
            </Row>
            <p className={styles.formTitle}>
              What is the nature of your query?{" "}
            </p>
            <Row className="mt-4 mb-3">
              {AboutProjects?.map((item, index) => {
                return (
                  <Col lg={6} key={index}>
                    <label>
                      {item?.label}
                      <input
                        type="checkbox"
                        name={item?.label}
                        value={item?.value}
                        onChange={handleChange}
                      />
                      <span className={styles.checkmark} />
                    </label>
                  </Col>
                )
              })}
            </Row>
            {Object.keys(values?.projects)?.includes(
              "Other (Please specify)"
            ) && (
              <Form.Group
                controlId="other"
                className={`${styles.selectInput} ppcSelect`}
              >
                <Select
                  name="otherProject"
                  components={{
                    DropdownIndicator,
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={"Other (Please Specify)*"}
                  classNamePrefix="react-select"
                  onChange={e => {
                    if (errors.hasOwnProperty("otherProject")) {
                      delete errors["otherProject"]
                      setErrors(errors)
                    }
                    setValues({ ...values, otherProject: e.value })
                  }}
                  options={options}
                  indicatorSeparator={false}
                />
                {errors.otherProject && (
                  <p type="invalid" className={styles.errorTxt}>
                    {errors.otherProject}
                  </p>
                )}
              </Form.Group>
            )}
            <p className={styles.formTitle}>Describe your project briefly? *</p>
            <Form.Group controlId="brief" className={styles.formInput}>
              <Form.Control
                as="textarea"
                rows={6}
                // type="text"
                placeholder="Describe your project briefly"
                name="message"
                value={values.message}
                onChange={handleChange}
                isInvalid={errors.message}
              />
              <Form.Control.Feedback type="invalid">
                {errors.message}
              </Form.Control.Feedback>
            </Form.Group>
            <label>
              I understand and agree to the{" "}
              <a href="/terms-conditions/" target="_blank">
                terms & conditions. *
              </a>
              <input
                type="checkbox"
                name={"terms"}
                value={values?.terms}
                onChange={checbox}
              />
              <span className={styles.checkmark} />
            </label>
            {errors.terms && (
              <p type="invalid" className={styles.errorTxt}>
                {errors.terms}
              </p>
            )}
            <div className="mb-1">
              {recaptchaNeeded && (
                <ReCAPTCHA
                  sitekey={SITE_KEY}
                  onChange={onChangeReCaptcha}
                  onExpired={() => {
                    values["captcha"] = null
                    setValues({ ...values })
                    setErrors({ ...errors, captcha: "This field is required!" })
                  }}
                />
              )}
              {errors.captcha && (
                <p type="invalid" className={styles.errorTxt}>
                  {errors.captcha}
                </p>
              )}
            </div>
            <div className={styles.button}>
              <button
                className={`btn_black_border ${
                  status === "submitting" ? "disableBtn2" : ""
                }`}
                disabled={status === "submitting" ? true : false}
                id="ppc_submit_btn"
              >
                {status === "submitting" ? (
                  <>
                    <Spinner
                      variant="dark"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Request A Quote</span>
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  )
}

export default PpcForm
